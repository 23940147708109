<template>
  <b-modal
    :visible="visible"
    footer-class="d-none"
    size="lg"
    @change="$emit('update:visible', $event)"
    @show="$emit('show', $event)"
    @hidden="$emit('update:edit', false)"
  >
    <template #modal-title>
      {{ isExistingEvent ? $t('calendar.update-meeting') : $t('calendar.new-meeting') }}
      <b-link v-if="isExistingEvent" class="event-link" :to="eventLocation">
        <feather-icon icon="ExternalLinkIcon" />
      </b-link>
    </template>
    <b-spinner
      v-if="loading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <CalendarEventForm
      v-else
      :visible="visible"
      :event="event"
      :clear-event-data="clearEventData"
      @update:visible="$emit('update:visible', $event)"
      @remove-event="$emit('remove-event', $event)"
      @add-event="$emit('add-event', $event)"
      @update-event="$emit('update-event', $event)"
    />
  </b-modal>
</template>

<script>
// import { BModal, BLink, BSpinner } from 'bootstrap-vue';
// import CalendarEventForm from '../calendar-event-form/CalendarEventForm.vue';

export default {
  name: 'CalendarEventFormModal',
  components: {
    // BModal,
    // BLink,
    // BSpinner,
    CalendarEventForm: () => import ('../calendar-event-form/CalendarEventForm.vue'  /* webpackChunkName: "CalendarEventForm" */),
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      default: () => () => {},
    },
  },
  computed: {
    isExistingEvent() {
      return !!this.event.id;
    },
    eventLocation() {
      return { name: 'event', params: { id: this.event.id, communityId: this.$route.params.communityId } };
    },
  },
};
</script>

<style lang="scss" scoped>
  .event-link {
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: top;
  }
</style>
